// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const config = {
  loginEndpoint: 'https://devapi.tntdash.one/api/authentication/login/',
  refreshEndpoint: 'https://devapi.tntdash.one/api/authentication/login/refresh/'
}

const { jwt } = useJwt(config)

export default jwt
