// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import api from '../../../../utility/axios'


export const fetchLabels = createAsyncThunk(
  'notes/loadLabels',
  async () => {
    const response = await api.get('/api/label')
    return response.data.data
  }
)


export const fetchNotes = createAsyncThunk(
  'notes/fetchNotes',
  async ({label, query}) => {
    const response = await api.get('/api/note', {
      params: {
        isArchived: label === 'archived',
        labelId: label && label !== 'archived' ? label : null,
        searchQuery: query
      }
    })
    return response.data.data
  }
)


export const fetchRoleOptions = createAsyncThunk(
  'notes/fetchRoleOptions',
  async () => {
    const response = await api.get('/api/roles/getPrivilegeRoleList')
    return response.data.data
  }
)


export const noteSlice = createSlice({
  name: 'notes',
  initialState: {
    labels: [],
    notes: [],
    roleOptions: [],
    filters: {
      label: null,
      query: null
    }
  },
  reducers: {
    // Labels
    addLabel: {
      reducer(state, action) {
        state.labels.push(action.payload)
      },
      prepare(id, name) {
        return {payload: {id, name}}
      }
    },
    removeLabel: (state, action) => {
      state.labels = state.labels.filter(label => label.id !== action.payload)
    },
    updateLabel: {
      reducer(state, action) {
        state.labels = state.labels.map(label => {
          if (label.id === action.payload.id) return action.payload
          else return label
        })
      },
      prepare(id, name) {
        return {payload: {id, name}}
      }
    },

    // Notes
    addNote: (state, action) => {
      state.notes.unshift(action.payload)
    },
    updateNote: (state, action) => {
      state.notes = state.notes.map(note => (note.id === action.payload.id ? action.payload : note))
    },
    removeNote: (state, action) => {
      state.notes = state.notes.filter(note => note.id !== action.payload)
    },

    // Filters
    updateFilters: (state, action) => {
      state.filters = action.payload
    },
    updateFilter: {
      reducer(state, action) {
        state.filters[action.payload.name] = action.payload.value
      },
      prepare(name, value) {
        return {payload: {name, value}}
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.labels = action.payload
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.notes = action.payload
      })
      .addCase(fetchRoleOptions.fulfilled, (state, action) => {
        state.roleOptions = action.payload
      })
  }
})

export const ACTIONS = noteSlice.actions
export const getLabels = state => state.notes.labels
export const getNotes = state => state.notes.notes
export const getRoleOptions = state => state.notes.roleOptions
export const getFilters = state => state.notes.filters

export default noteSlice.reducer
