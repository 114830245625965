import mock from '../mock'
// import {nanoid} from "@reduxjs/toolkit"
// /*eslint-disable */
// const previousDay = new Date(new Date().gettimestamp() - 24 * 60 * 60 * 1000)
// const dayBeforePreviousDay = new Date(new Date().gettimestamp() - 24 * 60 * 60 * 1000 * 2)
import data from './chat-data.json'
/*eslint-enable */

/*const reOrderChats = (arr, from, to) => {
  const item = arr.splice(from, 1)
  // Move the item to its new position
  arr.splice(to, 0, item[0])
}*/

// ------------------------------------------------
// GET: Return Chat List
// ------------------------------------------------
mock.onGet('/chats/').reply(() => [200, data.chatList])

// ------------------------------------------------
// GET: Return More Chats
// ------------------------------------------------
mock.onGet('/chats/load-more/').reply(() => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve([200, data.moreChats])
    }, 2000)
  })
})

// ------------------------------------------------
// GET: Return More Messages
// ------------------------------------------------
mock.onGet('/chats/messages/load-more/').reply(() => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve([200, data.moreMessages])
    }, 2000)
  })
})

// ------------------------------------------------
// GET: Return Active Chat Messages
// ------------------------------------------------
mock.onGet('/chats/show/').reply(({chatId}) => {

  const _chatId = Number(chatId)
  const chat = data.chatLog.find(chat => chat.contactId === _chatId)

  return [200, chat]
})
