import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../utility/axios'
import navigation from '../navigation/vertical'


// eslint-disable-next-line no-unused-vars
const staticChatLinks = [
  {
    id: 'chat_channel_active',
    title: 'All Messages',
    badge: 'light-warning',
    badgeText: 0,
    navLink: '/chats/active'
  }
]


const getChatLinks = channelId => [
  {
    id: 'all_messages',
    title: 'Active Messages',
    navLink: `/chats/channels/${channelId}/active`
  },
  {
    id: 'own_messages',
    title: 'Own Messages',
    navLink: `/chats/channels/${channelId}/mine`
  },
  {
    id: 'archived',
    title: 'Archived',
    navLink: `/chats/channels/${channelId}/archive`
  }
]


export const loadChatMenu = createAsyncThunk(
  'menu/loadChatMenu',
  async () => {
    const response = await api.get('/api/category/getCategoryMenus')
    return response.data.data
  }
)


export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    data: navigation
  },
  reducers: {
    initialize: (state, action) => {
      if (
        action.payload?.role && action.payload?.role?.toUpperCase() !== 'AGENT'
      ) {
        const settings = state.data.find(item => item.id === 'settings')
        if (!settings.children.find(item => item.id === 'categories')) {
          settings.children.push(...[
            {
              id: 'aiResponses',
              title: 'AI Responses',
              navLink: '/settings/ai-responses'
            },
            {
              id: 'categories',
              title: 'Categories',
              navLink: '/settings/categories'
            },
            {
              id: 'channels',
              title: 'Channels',
              navLink: '/settings/channels'
            },
            {
              id: 'channelGroups',
              title: 'Channel Groups',
              navLink: '/settings/channel-groups'
            },
            {
              id: 'emailAccounts',
              title: 'Email Accounts',
              navLink: '/settings/email-accounts'
            },
            {
              id: 'emailAddresses',
              title: 'Email Addresses',
              navLink: '/settings/email-addresses'
            },
            {
              id: 'languages',
              title: 'Languages',
              navLink: '/settings/languages'
            },
            {
              id: 'users',
              title: 'Users',
              navLink: '/settings/users'
            },
            {
              id: 'userFeedback',
              title: 'User Feedback',
              navLink: '/settings/user-feedback'
            }
          ])
          settings.children.sort((m, n) => {
            if (m.id < n.id) return -1
            else if (m.id > n.id) return 1
            else return 0
          })
        }
      }
    },

    updateChannelCount: {
      reducer(state, action) {
        const group = state.data.find(item => item.id === 'chat')?.children.find(item => item.id === action.payload.id)
        if (group) group['badgeText'] = action.payload.count
      },
      prepare(channelGroup, count) {
        return {
          payload: { id: `chat_channel_${channelGroup}`, count }
        }
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(loadChatMenu.fulfilled, (state, action) => {
      const options = action.payload.map(item => {
        const labels = item.categories.map(_item => {
          return {
            id: `label_${_item['categoryId']}`,
            title: _item['categoryName'],
            badge: 'light-warning',
            badgeText: _item['unreadMessageCount'],
            navLink: `/chats/channels/${item['groupId']}/categories/${_item.categoryId}`
          }
        })

        return {
          id: `chat_channel_${item['groupId']}`,
          title: item['groupName'],
          badge: 'light-warning',
          badgeText: item['unreadMessageCount'],
          children: [...getChatLinks(item['groupId']), ...labels]
        }
      })

      state.data = state.data.map(item => {
        if (item.id === 'chat') return {
          ...item,
          children: [...staticChatLinks, ...options]
        }
        else return item
      })
    })
  }
})


export const getMenuData = state => state.menu.data
export const { initialize, updateChannelCount } = menuSlice.actions
export default menuSlice.reducer