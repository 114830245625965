// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import api from "../utility/axios"


const config = useJwt.jwtConfig

export const loadInitialUser = createAsyncThunk(
  'auth/getInitialUser',
  async () => {
    try {
      const response = await api.get('/api/authentication/myInfo')
      const data = response.data
      if (data['succeeded']) return {...data.data, isAuthenticated: true, isOnline: true}
      else return {userName: null, fullName: null, isAuthenticated: false}
    } catch (e) {
      return {userName: null, fullName: null, isAuthenticated: false}
    }
  }
)

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: {userName: null, fullName: null, isAuthenticated: false}
  },
  reducers: {
    handleLogin: (state, action) => {
      const data = {...action.payload.userData, isAuthenticated: true}
      delete data['assertionOptions']
      state.userData = data

      // For JWT
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload.userData))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = {userName: null, fullName: null, isAuthenticated: false}

      // For JWT
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    enableTwoFA: state => {
      state.userData.twoFactorEnabled = true
    },
    disableTwoFA: state => {
      state.userData.twoFactorEnabled = false
    }
  },
  extraReducers: builder => {
    builder.addCase(loadInitialUser.fulfilled, (state, action) => {
      state.userData = action.payload
    })
  }
})

export const {handleLogin, handleLogout, enableTwoFA, disableTwoFA} = authSlice.actions
export const getCurrentUser = state => state.auth.userData

export default authSlice.reducer
