// ** React Imports
import {forwardRef, useState} from "react"

// ** Third Party Components
import Proptypes from "prop-types"
import classnames from "classnames"

// ** Reactstrap Imports
import { Badge } from "reactstrap"

const Avatar = forwardRef((props, ref) => {
  // ** Props
  const {
    img,
    size,
    icon,
    backgroundColor,
    color,
    status,
    badgeUp,
    content,
    tag: Tag,
    initials,
    imgWidth,
    className,
    badgeText,
    imgHeight,
    badgeColor,
    imgClassName,
    contentStyles,
    ...rest
  } = props

  const [showInitials, setShowInitials] = useState(false) // Show initials if image fails to load.

  // ** Function to extract initials from content
  const getInitials = (str) => {
    const results = []
    const wordArray = str.split(" ")
    const length = wordArray.length

    results.push(wordArray[0][0])
    if (length > 1) results.push(wordArray[length - 1][0])

    return results.join("").toUpperCase() // Transform to uppercase.
  }

  return (
    <Tag
      className={classnames("avatar", {
        [className]: className,
        [`bg-${color}`]: color && !backgroundColor,
        [`avatar-${size}`]: size
      })}
      style={{backgroundColor}}
      ref={ref}
      {...rest}
    >
      {!img || showInitials ? (
        <span
          className={classnames("avatar-content", {
            "position-relative": badgeUp
          })}
          style={{height: imgHeight || size || 32, width: imgWidth || size || 32, ...contentStyles}}
        >
          {initials || showInitials ? getInitials(content) : content}

          {icon ? icon : null}
          {badgeUp ? (
            <Badge
              color={badgeColor ? badgeColor : "primary"}
              className="badge-sm badge-up"
              pill
            >
              {badgeText ? badgeText : "0"}
            </Badge>
          ) : null}
        </span>
      ) : (
        <img
          className={classnames({
            [imgClassName]: imgClassName
          })}
          onError={() => setShowInitials(true)}
          src={img}
          // alt="avatarImg"   // Removed ALT tag due to rendering issues.
          height={imgHeight || size || 32}
          width={imgWidth || size || 32}
        />
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size
          })}
        ></span>
      ) : null}
    </Tag>
  )
})

export default Avatar

// ** PropTypes
Avatar.propTypes = {
  icon: Proptypes.node,
  src: Proptypes.string,
  badgeUp: Proptypes.bool,
  content: Proptypes.string,
  badgeText: Proptypes.string,
  className: Proptypes.string,
  imgClassName: Proptypes.string,
  contentStyles: Proptypes.object,
  // size: Proptypes.oneOf(["sm", "lg", "xl"]),
  size: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  tag: Proptypes.oneOfType([Proptypes.func, Proptypes.string]),
  status: Proptypes.oneOf(["online", "offline", "away", "busy"]),
  imgHeight: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  imgWidth: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  backgroundColor: Proptypes.string,
  badgeColor: Proptypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "info",
    "warning",
    "dark",
    "light-primary",
    "light-secondary",
    "light-success",
    "light-danger",
    "light-info",
    "light-warning",
    "light-dark"
  ]),
  color: Proptypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "info",
    "warning",
    "dark",
    "light-primary",
    "light-secondary",
    "light-success",
    "light-danger",
    "light-info",
    "light-warning",
    "light-dark"
  ]),
  initials(props) {
    if (props["initials"] && props["content"] === undefined) {
      return new Error("content prop is required with initials prop.")
    }
    if (props["initials"] && typeof props["content"] !== "string") {
      return new Error("content prop must be a string.")
    }
    if (
      typeof props["initials"] !== "boolean" &&
      props["initials"] !== undefined
    ) {
      return new Error("initials must be a boolean!")
    }
  }
}

// ** Default Props
Avatar.defaultProps = {
  tag: "div"
}
