import React, { useEffect, useState } from "react"
import DefaultAvatar from '@components/avatar'


export const colours = ["#ff500f", "#2dc6ff", "#008080", "#00bdff", "#e0115f", "#787878", "#d8183b", "#7a34bc"]

const Avatar = ({user, className, size, status}) => {
  const [color, setColor] = useState('')

  useEffect(() => {
    setColor(colours[Math.floor(Math.random() * 8)])
  }, [])

  return (
    <DefaultAvatar className={className}
                   content={user?.fullName || ''}
                   img={user?.profileImage}
                   initials={!user?.profileImage}
                   size={size}
                   status={status}
                   backgroundColor={user?.profileBackgroundColor || color} />
  )
}

export default Avatar