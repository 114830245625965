// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import api from '../../../utility/axios'

export const loadCurrentMonthData = createAsyncThunk('dashboard/fetchMonthWiseData', async () => {

})

export const loadMessagesByChannels = createAsyncThunk('dashboard/fetchMessagesByChannels', async () => {

})

export const loadMessagesByDays = createAsyncThunk('dashboard/fetchMessagesByDays', async () => {

})

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    currentMonthData: null,
    messagesByChannels: null,
    messagesByDays: null
  },
  extraReducers: builder => {
    builder.addCase(loadCurrentMonthData.fulfilled, (state, action) => {
      state.currentMonthData = action.payload.data
    })
    builder.addCase(loadMessagesByChannels.fulfilled, (state, action) => {
      state.messagesByChannels = action.payload.data
    })
    builder.addCase(loadMessagesByDays.fulfilled, (state, action) => {
      state.messagesByDays = action.payload.data
    })
  }
})

export const getCurrentMonthData = state => state.dashboard.currentMonthData
export const getMessagesByChannels = state => state.dashboard.messagesByChannels
export const getMessagesByDays = state => state.dashboard.messagesByDays

export default dashboardSlice.reducer