// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../../utility/axios'

export const getMails = createAsyncThunk('appEmail/getMails', async params => {
  const response = await axios.get('/api/email', { params })
  return {
    params,
    data: response.data.data
  }
})

export const getMailAccounts = createAsyncThunk('appEmail/getMailAccounts', async () => {
  const response = await axios.get('/api/email/getAllEmailAccounts')
  return response.data.data
})

export const getFolders = createAsyncThunk('appEmail/getFolders', async ({ accountId }) => {
  const response = await axios.get('/api/email/getEmailFolders', {
    params: {
      emailAccountId: accountId
    }
  })
  return response.data.data
})

export const getLabels = createAsyncThunk('appEmail/getLabels', async ({ accountId }) => {
  const response = await axios.get('/api/emailLabel', {
    params: {
      emailAccountId: accountId
    }
  })
  return response.data.data
})

export const getAddresses = createAsyncThunk('appEmail/getAddresses', async ({ accountId }) => {
  const response = await axios.get('/api/emailAddress', {
    params: {
      emailAccountId: accountId
    }
  })
  return response.data.data
})

export const appEmailSlice = createSlice({
  name: 'appEmail',
  initialState: {
    selectedAccount: null,
    mailAccounts: [],
    addresses: [],
    folders: [],
    staticFolders: {},
    dynamicFolders: [],
    labels: [],
    mails: [],
    params: {
      folderId:'',
      searchQuery: ''
    },
    selectedMails: [],
    currentMail: null,
    paginator: {
      current: 1,
      hasNext: false
    }
  },
  reducers: {
    updateMail: {
      reducer(state, action) {
        const data = action.payload.data
        const mail = state.mails.find(mail => mail.id === data.id)

        if (!mail) {
          if (action.payload.add) state.mails.unshift(data)
        } else {
          const folders = data.folders.map(item => item.id)
          if (!action.payload.add && !folders.includes(state.params.folderId)) {
            state.mails = state.mails.filter(mail => mail.id !== data.id)
          } else {
            Object.keys(data).forEach(key => {
              mail[key] = data[key]
            })
          }
        }
      },
      prepare(data, add = false) {
        return {
          payload: { add, data }
        }
      }
    },

    selectAccount: (state, action) => {
      if (action.payload) state.selectedAccount = action.payload
      else state.selectedAccount = state.mailAccounts.length ? state.mailAccounts[0].id : null
    },

    selectCurrentMail: (state, action) => {
      state.currentMail = state.mails.find(mail => mail.id === action.payload)
    },

    selectMail: (state, action) => {
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.payload)) {
        selectedMails.push(action.payload)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.payload), 1)
      }
      state.selectedMails = selectedMails
    },

    selectAllMail: (state, action) => {
      const selectAllMailsArr = []
      if (action.payload) {
        selectAllMailsArr.length = 0
        state.mails.forEach(mail => selectAllMailsArr.push(mail.id))
      } else {
        selectAllMailsArr.length = 0
      }
      state.selectedMails = selectAllMailsArr
    },

    resetSelectedMail: state => {
      state.selectedMails = []
    },

    clearMails: state => {
      state.mails = []
    },

    updateParams: (state, action) => {
      state.params = { ...state.params, ...action.payload }
    }
  },

  extraReducers: builder => {
    builder
      .addCase(getMailAccounts.fulfilled, (state, action) => {
        state.mailAccounts = action.payload
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        state.addresses = action.payload
        // state.folders.sort((a, b) => a['displayOrder'] - b['displayOrder'])
      })
      .addCase(getFolders.fulfilled, (state, action) => {
        const folders = action.payload
        folders.sort((a, b) => a['displayOrder'] - b['displayOrder'])

        state.folders = []
        state.staticFolders = {}
        state.dynamicFolders = []

        folders.forEach(item => {
          const folderType = item['folderType'].toLowerCase()
          if (folderType === 'basic') {
            const name = item['folderName'].toLowerCase()
            state.staticFolders[name] = item
            state.folders.push(item)
          } else if (folderType === 'folder') {
            state.dynamicFolders = item.folders || []
            state.folders.push(...state.dynamicFolders)
          }
        })
      })
      .addCase(getLabels.fulfilled, (state, action) => {
        state.labels = action.payload
      })
      .addCase(getMails.fulfilled, (state, action) => {
        const data = action.payload.data
        let currMail = null

        if (state.currentMail !== null && state.currentMail !== undefined) {
          currMail = data.items.find(i => i.id === state.currentMail.id)
        }

        if (data.pageNumber === 1) state.mails = data.items
        else state.mails.push(...data.items)

        state.currentMail = currMail
        state.params = action.payload.params
        state.paginator = {
          current: data.pageNumber,
          hasNext: data.hasNextPage
        }
      })
  }
})

export const ACTIONS = appEmailSlice.actions
export const { selectMail, selectAllMail, resetSelectedMail, selectCurrentMail } = ACTIONS
export const getEmail = state => state.email
export const getParams = state => {
  return { ...state.email.params, selectedAccount: state.email.selectedAccount }
}

export default appEmailSlice.reducer
