// ** Reducers Imports
import auth from "./authentication"
import layout from "./layout"
import navbar from "./navbar"
import menu from "./menu"
import chat from '@src/views/apps/chat/store'
import agentChat from '@src/views/apps/agent-messaging/store'
import notes from '@src/views/apps/notes/store'
import boards from '@src/views/apps/scrum-board/store'
import email from '@src/views/apps/email/store'
import dashboard from '@src/views/dashboard/store'

const rootReducer = { auth, chat, menu, notes, boards, email, navbar, layout, dashboard, agentChat }

export default rootReducer
