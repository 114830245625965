// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../../utility/axios'
import {colours} from "../../../../components/Avatar"

let axiosController = new AbortController()


export const fetchChats = createAsyncThunk('appChat/fetchChats', async (
  {page, category, query, channels, channelGroup}
) => {

  let listType = null
  let categoryId = null

  if (['ACTIVE', 'MINE', 'ARCHIVE'].includes(category?.toUpperCase())) listType = category
  else categoryId = category

  /*const channelIds = channels?.map(channel => `&channelIds=${channel}`) || ''
  const params = `?pageNumber=${page || ''}&term=${query || ''}&listType=${listType || ''}&categoryId=${categoryId || ''}${channelIds}`
  console.log('params', params)

  const response = await axios.get(`/api/contact/getMessageContacts/${params}`)*/

  const response = await axios.post('/api/contact/getMessageContacts/', {
    pageNumber: page,
    term: query,
    channelIds: channels,
    listType,
    channelGroupId: channelGroup,
    categoryId
  })
  if (response.data['succeeded']) return response.data.data
})


export const selectChat = createAsyncThunk('appChat/selectChat', async (
  {chatId, isGroup, agent, canRead}, {getState}
) => {
  const chatHistory = getState().chat.history
  const history = chatHistory.find(_chat => _chat.id === chatId)

  // Return from history
  if (history) {
    console.log('can read', canRead)
    if (canRead) axios.post('api/contact/markAsRead', {
      contactId: chatId
    }).then().catch(error => console.log(error))
    return {id: chatId, isGroup, agent, history}
  }

  // Fetch and return
  const response = await axios.get('/api/message/', {
    params: {contactId: chatId},
    signal: axiosController.signal
  })
  if (response.data['succeeded']) {
    if (canRead) axios.post('api/contact/markAsRead', {
      contactId: chatId
    }).then().catch(error => console.log(error))

    const _response = await axios.get('/api/message/getPinnedMessages', {
      params: {contactId: chatId}
    })

    return {id: chatId, isGroup, agent, ...response.data.data, pinnedMessages: _response.data.data}
  }
})

export const loadMoreMessages = createAsyncThunk(
  'appChat/loadMoreMessages',
  async ({chatId, page}) => {
    const response = await axios.get('/api/message/', {
      params: {contactId: chatId, pageNumber: page},
      signal: axiosController.signal
    })
    if (response.data['succeeded']) return {id: chatId, ...response.data.data}
  })

export const fetchNotes = createAsyncThunk(
    'appChat/fetchNotes',
    async ({ chatId }) => {
      const response = await axios.get('/api/ContactNote', {
        params: {contactId: chatId},
        signal: axiosController.signal
      })

      console.log("response", response.data.data)

      if (response.data['succeeded']) return response.data.data
    })

export const deleteNote = createAsyncThunk(
    'appChat/deleteNote',
    async ({ noteId }) => {
      const response = await axios.delete('/api/ContactNote', {
        params: {id: noteId},
        signal: axiosController.signal
      })

      console.log("response", response)

      if (response.data['succeeded']) return {id: noteId, ...response.data.data}
    })

export const updateNote = createAsyncThunk(
    'appChat/updateNote',
    async ({ noteId, note }) => {
      const response = await axios.put('/api/ContactNote', {
        id: noteId,
        note
      })

      console.log("response", response)

      if (response.data['succeeded']) return {id: noteId, ...response.data.data}
    })

const chatListSort = (a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate)
const messagesSort = (a, b) => new Date(a['createdDate']) - new Date(b['createdDate'])

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chatList: {
      items: [],
      paginator: {
        current: 1,
        hasNext: false
      }
    },
    activeChat: {
      isReady: false,
      id: null,
      isGroup: false,
      agent: null,
      pinnedMessages: [],
      messages: [],
      paginator: {
        current: 1,
        hasNext: false
      }
    },
    history: [],
    receiver: {},
    notes: []
  },
  reducers: {
    // Receiver
    setReceiver: {
      reducer(state, action) {
        state.receiver = action.payload
      },
      prepare(id, fullName, profileImage, online, totalNotesCount, profileBackgroundColor) {
        return {
          payload: {id, fullName, profileImage, online, totalNotesCount, profileBackgroundColor}
        }
      }
    },
    unsetReceiver: (state) => {
      state.receiver = {}
    },

    // Chat list pins
    pinChat: (state, action) => {
      let found = false
      state.chatList.items = state.chatList.items.map(_chat => {
        if (_chat.id === action.payload) {
          found = true
          return {
            ..._chat,
            isPinned: true
          }
        } else return _chat
      })
      if (found) state.chatList.items.sort(chatListSort)
    },

    unPinChat: (state, action) => {
      let found = false
      state.chatList.items = state.chatList.items.map(_chat => {
        if (_chat.id === action.payload) {
          found = true
          return {
            ..._chat,
            isPinned: false
          }
        } else return _chat
      })
      if (found) state.chatList.items.sort(chatListSort)
    },

    // Active chat
    resetActiveChat: state => {
      axiosController.abort()

      const activeChat = state.activeChat
      const messageCount = activeChat.messages.length
      const slice = messageCount >= 25 ? -25 : -messageCount
      const messages = activeChat.messages.slice(slice)

      if (activeChat.id) {
        let found = false
        state.history = state.history.map(_chat => {
          if (_chat.id === activeChat.id) {
            found = true
            return {
              isReady: true,
              id: activeChat.id,
              isGroup: activeChat.isGroup,
              pinnedMessages: activeChat.pinnedMessages,
              paginator: activeChat.paginator,
              messages
            }
          } else return _chat
        })
        if (!found) state.history.push({
          ...state.activeChat,
          messages
        })
      }

      state.activeChat = {
        isReady: false,
        id: null,
        isGroup: false,
        pinnedMessages: [],
        messages: [],
        paginator: {
          current: 1,
          hasNext: false
        }
      }
      axiosController = new AbortController()
    },

    takeover: {
      reducer(state, action) {
        if (state.activeChat.id === action.payload.id) {
          state.activeChat.agent = action.payload['assignedAgent']
        }

        let found = false
        state.chatList.items = state.chatList.items.map(_item => {
          if (_item.id === action.payload.id) {
            found = true
            return action.payload
          } else return _item
        })

        if (found) state.chatList.items.sort(chatListSort)
        else {
          state.chatList.items.unshift(action.payload)
          // state.chatList.items.pop()
        }
      },
      prepare(data) {
        return {
          payload: data
        }
      }
    },

    updateChatList: {
      reducer(state, action) {
        const data = action.payload.data
        let found = false

        state.chatList.items = state.chatList.items.map(_item => {
          if (_item.id === data.id) {
            found = true
            return data
          } else return _item
        })

        if (found) {
          if (state.activeChat.id === data.id && state.activeChat.agent !== data['assignedAgent']) {
            state.activeChat.agent = data['assignedAgent']
          }
          state.chatList.items.sort(chatListSort)
        } else {
          if (action.payload.append) {
            state.chatList.items.unshift(data)
            // state.chatList.items.pop()
          }
        }
      },
      prepare(data, append = false) {
        return {
          payload: {data, append}
        }
      }
    },

    addContact: {
      reducer(state, action) {
        const data = action.payload.data
        const contact = state.chatList.items.find(_item => _item.id === data.id)
        if (!contact) {
          state.chatList.items.push(data)
          state.chatList.items.sort(chatListSort)
        }
      },
      prepare(data) {
        return {
          payload: {data}
        }
      }
    },

    removeContact: {
      reducer(state, action) {
        state.chatList.items = state.chatList.items.filter(_item => _item.id !== action.payload.contactId)
      },
      prepare(contactId) {
        return {
          payload: {contactId}
        }
      }
    },

    // Active pins
    addPin: {
      reducer(state, action) {
        state.activeChat.pinnedMessages.push(action.payload)
      },
      prepare(id, sender, text, createdDate) {
        return {
          payload: {id, sender, text, createdDate}
        }
      }
    },
    removePin: (state, action) => {
      state.activeChat.pinnedMessages = state.activeChat.pinnedMessages.filter(
        _msg => _msg.id !== action.payload
      )
    },
    updatePin: {
      reducer(state, action) {
        const data = action.payload.data
        const pin = data.isPinned

        if (state.activeChat.id === data.contactId) {
          const message = state.activeChat.pinnedMessages.find(_message => _message.id === data.id)
          if (message && !pin) {
            state.activeChat.pinnedMessages = state.activeChat.pinnedMessages.filter(
              _msg => _msg.id !== data.id
            )
          } else if (!message && pin) state.activeChat.pinnedMessages.push(data)
        } else {
          const chat = state.history.find(_item => _item.id === data.contactId)
          if (chat) {
            const message = chat.pinnedMessages.find(_message => _message.id === data.id)
            if (message && !pin) {
              chat.pinnedMessages = chat.pinnedMessages.filter(
                _msg => _msg.id !== data.id
              )
            } else if (!message && pin) chat.pinnedMessages.push(data)
          }
        }
      },
      prepare(data) {
        return {
          payload: {data}
        }
      }

    },

    newMessage: {
      reducer(state, action) {
        if (state.activeChat.id === action.payload.contactId) state.activeChat.messages.push(action.payload)
        else {
          const chat = state.history.find(_item => _item.id === action.payload.contactId)
          if (chat) chat.messages.push(action.payload)
        }
      },
      prepare(data) {
        return {
          payload: data
        }
      }
    },

    messageDelivered: (state, action) => {
      const message = state.activeChat.messages.find(_message => _message.id === action.payload)
      if (message) message.isDelivered = true
    },

    editMessage: {
      reducer(state, action) {
        if (state.activeChat.id === action.payload.contactId) {
          const message = state.activeChat.messages.find(_message => _message.id === action.payload.messageId)
          if (message) {
            message.text = action.payload.text
            message.isEdited = true
          }
        } else {
          const chat = state.history.find(_item => _item.id === action.payload.contactId)
          const message = chat?.messages.find(_message => _message.id === action.payload.messageId)
          if (message) {
            message.text = action.payload.text
            message.isEdited = true
          }
        }
      },
      prepare(contactId, messageId, text) {
        return {
          payload: {contactId, messageId, text}
        }
      }
    },

    editMessageForAiResponse: {
      reducer(state, action) {
        if (state.activeChat.id === action.payload.contactId) {
          const message = state.activeChat.messages.find(_message => _message.id === action.payload.messageId)
          if (message) {
            message.aiResponse = action.payload.text
          }
        } else {
          const chat = state.history.find(_item => _item.id === action.payload.contactId)
          const message = chat?.messages.find(_message => _message.id === action.payload.messageId)
          if (message) {
            message.aiResponse = action.payload.text
          }
        }
      },
      prepare(contactId, messageId, text) {
        return {
          payload: {contactId, messageId, text}
        }
      }
    },

    deleteMessage: {
      reducer(state, action) {
        if (state.activeChat.id === action.payload.contactId) {
          state.activeChat.messages = state.activeChat.messages.filter(
            _msg => _msg.id !== action.payload.messageId
          )
        } else {
          const chat = state.history.find(_item => _item.id === action.payload.contactId)
          if (chat) chat.messages = chat.messages.filter(
            _msg => _msg.id !== action.payload.messageId
          )
        }
      },
      prepare(contactId, messageId) {
        return {
          payload: {contactId, messageId}
        }
      }
    },
    addNote: (state, action) => {
      const { note } = action.payload
      state.notes.unshift(note)
    },
    editNote: (state, action) => {
      const { note } = action.payload
      state.notes = state.notes.map(_note => {
        if (_note.id === note.id) {
          return {...note}
        } else {
          return _note
        }
      })
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchChats.fulfilled, (state, action) => {
        const newItems = action.payload.items.map(contact => {
          const color = colours[Math.floor(Math.random() * 8)]
          return contact.profileBackgroundColor === "" || contact.profileBackgroundColor === null ? { ...contact, profileBackgroundColor: color } : contact
        })

        if (action.payload.pageNumber === 1) state.chatList.items = newItems
        else {
          const contacts = []
          state.chatList.items = [...state.chatList.items, ...newItems].filter(item => {
            if (contacts.includes(item.id)) return false
            else {
              contacts.push(item.id)
              return true
            }
          })
        }

        state.chatList.paginator = {
          current: action.payload.pageNumber,
          hasNext: action.payload.hasNextPage
        }
        // state.chatList.items.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate))
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        if (action.payload.history) {
          state.activeChat = {...action.payload.history, agent: action.payload.agent}
        } else {
          state.activeChat.isReady = true
          state.activeChat.agent = action.payload.agent
          state.activeChat.id = action.payload.id
          state.activeChat.isGroup = action.payload.isGroup
          state.activeChat.messages = action.payload.items
          state.activeChat.pinnedMessages = action.payload.pinnedMessages
          state.activeChat.paginator = {
            current: action.payload.pageNumber,
            hasNext: action.payload.hasNextPage
          }
          state.activeChat.messages.sort(messagesSort)
        }
      })
      .addCase(loadMoreMessages.fulfilled, (state, action) => {
        if (action.payload.id === state.activeChat.id) {
          state.activeChat.messages.unshift(...action.payload.items)
          state.activeChat.paginator = {
            current: action.payload.pageNumber,
            hasNext: action.payload.hasNextPage
          }

          state.activeChat.messages.sort(messagesSort)
        }
      })
        .addCase(deleteNote.pending, (state, action) => {
          console.log(state, action)
        })
        .addCase(deleteNote.fulfilled, (state, action) => {
          console.log(state, action)
          const note = action.payload
          console.log("note", note)
          state.notes = state.notes.filter((_note) => _note.id !== note.id)
        })
        .addCase(updateNote.pending, (state, action) => {
          console.log(state, action)
        })
        .addCase(updateNote.fulfilled, (state, action) => {
          console.log(state, action)
        })
        .addCase(fetchNotes.pending, (state, action) => {
          console.log(state, action)
        })
        .addCase(fetchNotes.fulfilled, (state, action) => {
          state.notes = action.payload
        })
  }
})

export const {
  setReceiver,
  resetActiveChat,
  pinChat,
  unPinChat,
  addPin,
  removePin,
  updatePin,
  newMessage,
  messageDelivered,
  editMessage,
  editMessageForAiResponse,
  deleteMessage,
  takeover,
  updateChatList,
  addContact,
  removeContact,
  editNote,
  addNote
} = appChatSlice.actions
export const getChatList = state => state.chat.chatList
export const getActiveChat = state => state.chat.activeChat
export const getReceiver = state => state.chat.receiver
export const getNotes = state => state.chat.notes

export default appChatSlice.reducer
