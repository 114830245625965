import axios from "axios"


const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true
})


api.interceptors.response.use(
  response => {
    if (!response.data['succeeded']) {
      return Promise.reject({response})
    }
    return response
  },
  error => {
    return Promise.reject(error)
  }
)


export default api
